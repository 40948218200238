<template>
    <!-- <div class="n_button_div ion-text-center" v-show="paginationData.totalPage > 0 && paginationData.lastPage > globalSet.apiParams.page">
        <button class="n_button" @click="moreList()">More loading</button>
    </div> -->
    <ion-infinite-scroll v-if="paginationData.total > 0 && paginationData.total > paginationData.page * paginationData.size" threshold="100px" @ionInfinite="loadMore($event)" >
        <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
        </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</template>
<script>
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'LazyloadLoadingLayout',
        props: {
            paginationData: {
                type: Object,
                default: {
                    total: 0,
                    page: 0,
                    size: 0,
                }
            },
            setData: {
                type: Function,
                default: () => {}
			},
			boardType: {}
        },
        mixins: [],
        data() {
            return {
            }
        },
        computed: {

        },
        mounted() {
            
        },
        methods: {
            loadMore($event) {
                let page= this.paginationData.page;
                page = page + 1;
				this.setData('page', page);
                EventBus.$emit('getBoardList')
                setTimeout(() => {
                    if(this.paginationData.totalPage > page) {
                        $event.target.complete();
                    }else{
                        $event.target.disabled = true;
                    }
                }, 500)
            },
        }
    }
</script>